import React, {useState, useEffect, useRef, createRef} from "react"
import {useTranslation} from "react-i18next"
import * as queryString from "query-string"
import isClient from "is-client"
import Api from "vactory-gatsby-api"
import {searchPageSize, SearchPosts} from 'vactory-gatsby-search'
import {Box, Container, Heading} from "vactory-ui";
import {Pagination, LoadingOverlay} from 'vactory-gatsby-ui'
import { SearchForm } from './search.form'

const StyledBox = props => <Box sx={{
        p: 'large',
        my: 'large',
        bg: 'white',
        boxShadow: 'cards',
    }}
    {...props}
    />

const SearchContainer = ( props ) => {
    const {pageContext: {node}, navigate, location: {search: locationSearch}} = props;
    const {t} = useTranslation();
    const searchField = createRef();
    const isFirstRun = useRef(true);

    const isFrontClient = isClient();
    const queryParams = isFrontClient ? queryString.parse(locationSearch) : {q: ""};

    const [count, setCount] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [searchTerm, setsearchTerm] = useState(queryParams.q)
    const [pageSize] = useState(searchPageSize);
    const [page, setPage] = useState(1);
    const [pageItems, setPageItems] = useState([]);


    const handleSearchSubmit = (event) => {
        event.preventDefault()
        const term = searchField.current.value

        if (!term) return;

        setsearchTerm(term)
    };

    const handlePaginationChange = (selected) => {
        setPage(selected)
    };

    const fetchData = (term, page, limit, lang) => {
        setIsLoading(true)
        Api.getRest('_search', {
            params: {
                q: term,
                limit: limit,
                pager: page,
            },
        }, lang)
        .then(res => {
            const resources = res.data.resources;
            const total = res.data.count;
    
            setPageItems(resources);
            setCount(total);
            setIsLoading(false)
        })
        .catch(err => {
            console.log(err)
        })
    };

    useEffect(() => {
        if (isFirstRun.current && !searchTerm) {
            isFirstRun.current = false;
            return;
        }

        navigate("?q="+searchTerm)
        fetchData(searchTerm, page, pageSize, node.langcode)
    }, [searchTerm, page, pageSize, node.langcode, navigate]);

    return (
        <Container>
            <Heading mt="medium" level={3}>{t('Rechercher')}</Heading>

            <StyledBox>
                <SearchForm fieldRef={searchField} defaultValue={searchTerm} onSubmit={handleSearchSubmit} />
                { (searchTerm && count > 0) &&
                <Box textAlign="right">
                    {t("Total résultats trouvés") + `(${count})`}
                </Box>
                }
            </StyledBox>

            <LoadingOverlay active={isLoading}>
                { !searchTerm &&
                <StyledBox>
                    {t('Saisissez vos mots-clés et cliquez sur rechercher pour voir les résultats')}
                </StyledBox>
                }

                { (searchTerm && !isLoading && pageItems.length <= 0) &&
                <StyledBox>
                    {t("Aucun résultat trouvé pour votre recherche.")}
                </StyledBox>
                }

                { (searchTerm && pageItems.length > 0) &&
                    <SearchPosts posts={pageItems}/>
                }
            </LoadingOverlay>

            {pageItems.length > 0 &&
            <Pagination
                total={count}
                pageSize={pageSize}
                current={page}
                onChange={handlePaginationChange}
            />
            }
        </Container>
    )
};

export default SearchContainer
