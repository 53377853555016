import React from "react"
import {Wysiwyg} from 'vactory-gatsby-ui'
import {Box} from "vactory-ui";
import {SearchResultCard} from "capital-azur/src/components"

export const SearchPosts = ({posts}) => {

    return (
        <Box>
            {posts.map( (item, index) => <SearchResultCard
                        key={index}
                        title={item.title}
                        excerpt={<Wysiwyg html={item.excerpt} />}
                        url={item.url}
                    />
            )}
        </Box>
    )
}
