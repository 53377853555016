import React from "react"
import {
    Button,
    Flex,
    Input,
} from "vactory-ui"
import { useTranslation } from "react-i18next"


export const SearchForm = ( props ) => {
    const { onSubmit: handleSearchSubmit, fieldRef, defaultValue } = props;
    const { t } = useTranslation();

    return (
        <form onSubmit={handleSearchSubmit}>
            <Flex flexWrap="wrap" justifyContent={['center', null, 'flex-start']}>
                <Input
                    variant="capitalAzur"
                    type="search"
                    name="search"
                    id="search"
                    size='large'
                    ref={fieldRef}
                    width={ [null, null, 1/2] }
                    placeholder={t("Mots-clés")}
                    defaultValue={defaultValue}
                    onChange={null}
                    sx={{
                        flex: '1',
                        my: 'small',
                    }}
                />
                <Button type="submit" sx={{
                    variant: 'buttons.primary',
                    size: 'sizes.large',
                    mx: 'medium',
                    my: 'small'
                }}>
                    {t("trouver")}
                </Button>
            </Flex>
        </form>
    )
}
